import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { setTemp } from '@store/actions';
import convertKeysToCamelCase from '@utils/convertKeysToCamelCase';
import Decision from './Decision';
import devicer from '../../../../../external-libs/devicer.min.js';
import { isSyntheticTest, platformName } from '../../../utils/platformBasedInfo';

const DecisionContainer = ({
  loan_id,
  loanType,
  loan_amount,
  loan_terms,
  loan_payment,
  fico,
  yearsInBusiness,
  loanAmount,
  businessState,
  businessRevenue,
  user,
  callApi,
  vendor_name,
  temp,
  setTemp,
  closeAlert,
  dataLayerPush,
  change,
  history,
  partnerCustomFields,
  vendorId,
  ...other
}) => {
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';
  useEffect(() => {
    if (process.env.STORYBOOK_TEST) return;

    if (platformName === 'tento') {
      var deviceFPOptions = {
        publicKey: process.env.SOCURE_PUBLIC_KEY,
        endpoint: process.env.SOCURE_URL,
        userConsent: true,
        context: 'transaction',
      };
      devicer.run(deviceFPOptions, function (response) {});
    }
  }, []);

  const routeDecision = ({ decision, fico, offers }) => {
    // close any existing alerts
    closeAlert();

    if (!isSyntheticTest) {
      // send a GTM event
      dataLayerPush('credit-decision', {
        decision,
      });
    }

    if (!isSyntheticTest) {
      analytics.track('Apply Form - Credit Decision', {
        decision,
      });
    }

    // use history.replace so that process cannot be back browsed
    switch (decision) {
      case DecisionBusiness.ProceedEQ:
      case DecisionBusiness.ProceedWC:
      case DecisionPersonal.ProceedEQ:
      case DecisionPersonal.ProceedWC:
      case 'proceed': // Sometimes backend might reply with "proceed" if someone forgot to handle new product type
        if (flags.termsAndDocuSign || process.env.STORYBOOK_TEST) {
          history.replace('/app/agreement/terms');
        } else {
          history.replace('/app/contact-soon');
        }
        break;
      case DecisionBusiness.ProceedPersonal:
        history.replace('/app/decision/personal');
        break;
      case 'offers':
        setTemp({
          pendingOffers: offers,
        });
        history.replace('/app/decision/offers');
        break;
      case DecisionBusiness.DeclinedError:
      case DecisionBusiness.DeclinedTimeInBusiness:
      case DecisionBusiness.DeclinedFico:
      case DecisionBusiness.DeclinedRevenue:
      case DecisionBusiness.DeclinedGeneral:
      case DecisionPersonal.DeclinedError:
      case DecisionPersonal.DeclinedTimeInBusiness:
      case DecisionPersonal.DeclinedFico:
      case DecisionPersonal.DeclinedRevenue:
      case DecisionPersonal.DeclinedGeneral:
        change('application', 'owner_1_fico', fico);
        history.replace('/app/decision/decline');
        break;
      case DecisionBusiness.DeclinedFrozen:
      case DecisionPersonal.DeclinedFrozen:
        history.replace('/app/decision/frozen');
        break;
    }
  };

  const checkOffers = async () => {
    const post = {
      uuid: temp.pendingOffers.uuid,
      loan_id: loan_id,
    };

    const ready = await callApi('decision/check-offers', post, vendor_name); // TODO: in SF decisionCheckOffers

    const segmentPost = convertKeysToCamelCase(post);

    if (!isSyntheticTest) {
      analytics.track('Apply Form - Check Offers', segmentPost);
    }

    if (!ready.error && ready.ready) {
      if (ready.decision === 'decline') {
        routeDecision(ready);
      } else {
        setTemp({
          offersLoaded: true,
          offers: ready.offers,
        });
      }
    } else {
      setTemp({
        offersLoaded: false,
        offers: ready.offers,
      });
      return await checkOffers(post);
    }
  };

  return (
    <Decision
      checkOffers={checkOffers}
      {...{
        loan_id,
        loan_type: loanTypeSafe,
        loan_amount,
        loan_terms,
        loan_payment,
        user,
        callApi,
        vendor_name,
        temp,
        setTemp,
        closeAlert,
        dataLayerPush,
        change,
        history,
        fico,
        partnerCustomFields,
        vendorId,
        ...other,
      }}
    />
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    fico: selector(state, 'owner_1_fico'),
    loan_id: selector(state, 'loan_id'),
    loanType: selector(state, 'loan_type'),
    loan_amount: selector(state, 'loan_amount'),
    loan_terms: selector(state, 'loan_terms'),
    loan_payment: selector(state, 'loan_payment'),
    businessState: selector(state, 'business_state'),
    businessRevenue: selector(state, 'business_revenue'),
    yearsInBusiness: selector(state, 'business_years'),
    loanAmount: selector(state, 'loan_amount'),
    theme: state.theme,
    temp: state.temp,
    user: state.user,
    vendor_name: state.brand?.data?.vendor_name,
    partnerCustomFields: state.partnerCustomFields,
    vendorId: state.brand?.data?.vendor_id,
  };
};

const FullDecisionContainer = connect(mapStateToProps, { setTemp, change })(withRouter(DecisionContainer));

export default FullDecisionContainer;
