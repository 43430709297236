import React, { FC, ReactNode } from 'react';
import { DealsType } from 'src/js/types';
import { currency } from '@components/_fields/normalizers';
import {
  APPLY_INCOMPLETE_STAGES,
  Button,
  ButtonType,
  Card,
  CardProps,
  cn,
  List,
  LoanStage,
  Stack,
  Text,
} from 'shared-components';
import { BcmAccountListItemType } from 'src/js/api/getUserAccounts';
import { TextProps } from 'node_modules/shared-components/app/designsystem/Typography/Text';
import { BadgeCheck, Check, CheckCircle2 } from 'lucide-react';

export type ApplicationCardProps = {
  status: string;
  number?: string | number | ReactNode;
  label?: string | ReactNode;
  companyName?: string | ReactNode;
  textBody?: string | ReactNode;
  cardProps?: Partial<CardProps>;
  headlineProps?: Partial<TextProps>;
  subheadlineProps?: Partial<TextProps>;
  textBodyProps?: Partial<TextProps>;
  buttonProps?: ButtonType;
  numberProps?: Partial<TextProps>;
  icon?: ReactNode;
  additionalComponent?: ReactNode;
};

export const AccountApplicationCard: FC<ApplicationCardProps> = ({
  cardProps,
  headlineProps,
  companyName,
  textBodyProps,
  textBody,
  buttonProps,
  number,
  numberProps,
  icon,
  status,
  additionalComponent,
  label,
  subheadlineProps,
}) => {
  const cardType =
    status === 'noaccess' || status.startsWith('approved')
      ? 'default'
      : status !== 'noaccess' // requested
      ? 'current-spark'
      : 'default';
  const buttonType: ButtonType['variant'] =
    status === 'noaccess' ? 'primary-green' : status !== 'noaccess' ? 'primary-green' : 'primary-green';
  const numberBgColor =
    status === 'noaccess' || status.startsWith('approved')
      ? 'bg-dawn2'
      : status !== 'noaccess'
      ? 'bg-sparkHover'
      : 'bg-dawn2';
  const button = status === 'noaccess' ? 'Request' : 'View';

  return (
    <Card
      type={cardType}
      {...cardProps}
      className={cn('relative flex flex-col items-center gap-5', cardProps?.className ?? '')}
    >
      {number && (
        <Button
          variant="cta-active"
          disabled
          className={cn(`absolute p-0 w-[40px] h-[40px] border-none ${numberBgColor}`, {
            ['left-[15px] top-[15px]']: cardProps?.size !== 'small',
            ['left-[10px] top-[10px]']: cardProps?.size === 'small',
          })}
        >
          <Text variant="label-numbers" {...numberProps} className={cn('', cardProps?.className ?? '')}>
            {number}
          </Text>
        </Button>
      )}
      <div className="flex flex-col items-center gap-5 justify-between h-full flex-1">
        <Stack gapPx="0px" className="mt-[10px]">
          {label && (
            <Text
              variant="label-info"
              {...subheadlineProps}
              className={cn('text-center', subheadlineProps?.className ?? '')}
            >
              {label}
            </Text>
          )}
          {companyName && (
            <Text
              variant="headline-sm"
              {...headlineProps}
              className={cn('text-center', headlineProps?.className ?? '')}
            >
              {companyName}
            </Text>
          )}
        </Stack>
        {icon}
        {textBody && (
          <Text variant="body-general" {...textBodyProps} className={cn('text-center', textBodyProps?.className ?? '')}>
            {textBody}
          </Text>
        )}

        {status.startsWith('approved') && (
          <Text variant="body-general" {...textBodyProps} className={cn('text-center', textBodyProps?.className ?? '')}>
            Business Credit Monitoring Approved
          </Text>
        )}
        <Button
          variant={buttonType}
          {...buttonProps}
          className={cn('w-[175px] uppercase', buttonProps?.className ?? '')}
        >
          {buttonProps?.children ?? button}
        </Button>
        {additionalComponent}
      </div>
    </Card>
  );
};

const padZero = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const PickAccountTable: FC<{
  accounts: BcmAccountListItemType[];
  onAccountPicked: (account: BcmAccountListItemType) => void;
  onNewAccountPicked: () => void;
  isLoading: boolean;
}> = ({ accounts, onAccountPicked, isLoading, onNewAccountPicked }) => {
  return (
    <>
      <List containerProps={{ className: 'justify-center' }} itemContainerProps={{ className: 'm-[5px]' }}>
        <AccountApplicationCard
          status={'noaccess'}
          number={undefined}
          label={''}
          companyName="Apply for a new business"
          buttonProps={{
            onClick: () => onNewAccountPicked(),
            children: 'REQUEST',
            variant: 'cta-form',
            className: 'bg-spark hover:bg-sparkHover',
          }}
          textBody={undefined}
        />
        {accounts.map((account, index) => (
          <AccountApplicationCard
            status={account.bcmStatus ?? 'noaccess'}
            number={padZero(index + 1)}
            companyName={account.name ?? ''}
            label={undefined}
            headlineProps={{
              className: 'mt-[0.8rem]',
            }}
            icon={account.bcmStatus.startsWith('approved') ? <BadgeCheck width="40" height="40" /> : undefined}
            buttonProps={{
              onClick: () => onAccountPicked(account),
              isLoading: isLoading,
              ...(account.bcmStatus === 'noaccess'
                ? {
                    children: 'REQUEST',
                  }
                : account.bcmStatus.startsWith('approved')
                ? { children: 'VIEW' }
                : { disabled: true, children: 'SUBMITTED' }),
              variant: 'cta-form',
              className: 'bg-spark hover:bg-sparkHover',
            }}
            textBody={
              <Stack gapPx="10px" alignItems="center" justifyContent="center">
                {/* {account.type && (
                  <Text variant="body-general" className="text-center">
                    {account.type === 'WC' ? 'Working Capital' : account.type === 'EQ' ? 'Equipment Loan' : ''}
                  </Text>
                )}
                {account.termLength && (
                  <Text variant="body-general" className="text-center">
                    {account.termLength}{' '}
                    {account.termUnit === 'month'
                      ? 'Month'
                      : account.termUnit === 'year'
                      ? 'Year'
                      : account.termUnit === 'day'
                      ? 'Day'
                      : 'Month'}{' '}
                    Term
                  </Text>
                )} */}
              </Stack>
            }
          />
        ))}
      </List>
    </>
  );
};
