import { Reducer, combineReducers } from 'redux';
import {
  SET_THEME,
  CLEAR_THEME,
  SET_CONFIG,
  SET_TEMP,
  CLEAR_TEMP,
  SET_DATA,
  CLEAR_DATA,
  LOAD_VENDOR_STARTED,
  LOAD_VENDOR_SUCCESS,
  LOAD_VENDOR_FAILURE,
  LOAD_VENDOR_DEFAULT,
  SET_USER,
  AUTH_FAILED,
  LOGOUT,
  REFRESH_USER_STARTED,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_FAILURE,
  SET_SOCURE_DEVICE_ID,
  SET_VALIDATON_TYPE,
  ConfigType,
  TempType,
  UserType,
  ValidationType,
  LogoPositionType,
  BrandType,
  AVAILABLE_TERM_OPTIONS,
} from './actions';
import { FormStateMap, reducer as formReducer } from 'redux-form';
import { changePriceToNumber } from '../utils/changePriceToNumber';
import { LoanType } from 'src/js/types';
import { ApplyWizardStepObject, STEP_HOMEPAGE } from '@components/ApplyWizard/ApplyWizardSteps';
import { ApplyWizardStepsWithComponents } from '@components/ApplyWizard/ApplyWizardStepsWithComponents';

const themeReducer: Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_THEME:
      return action.theme;
    case CLEAR_THEME:
      return {};
    default:
      return state;
  }
};

const initialState = {
  loaded: false,
  error: null,
  customVendor: false,
};

const configReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        BCC: {
          rate: 10,
          min: Number(state.brand.data?.loan_amount?.wc?.minimum) || 5000,
          max: Number(state.brand.data?.loan_amount?.wc?.maximum) || 250000,
          amount: state.brand.data?.loan_amount?.wc?.default
            ? Number(state.brand.data?.loan_amount?.wc?.default)
            : 150000,
          termOptions: AVAILABLE_TERM_OPTIONS,
          selectedTerm: 6,
        },
        LOC: {
          rate: 10,
          min: Number(state.brand.data?.loan_amount?.wc?.minimum) || 5000,
          max: Number(state.brand.data?.loan_amount?.wc?.maximum) || 250000,
          amount: state.brand.data?.loan_amount?.wc?.default
            ? Number(state.brand.data?.loan_amount?.wc?.default)
            : 150000,
          termOptions: AVAILABLE_TERM_OPTIONS,
          selectedTerm: 6,
        },
        PL: {
          rate: 10,
          min: Number(state.brand.data?.loan_amount?.wc?.minimum) || 5000,
          max: Number(state.brand.data?.loan_amount?.wc?.maximum) || 250000,
          amount: state.brand.data?.loan_amount?.wc?.default
            ? Number(state.brand.data?.loan_amount?.wc?.default)
            : 150000,
          termOptions: AVAILABLE_TERM_OPTIONS,
          selectedTerm: 6,
        },
        ...(action.config ?? {}),
      };

    case LOAD_VENDOR_STARTED:
      return {
        loaded: false,
        customVendor: true,
      };
    case LOAD_VENDOR_SUCCESS:
      return {
        loaded: true,
        error: null,
        customVendor: true,
        ...action.payload,
      };
    case LOAD_VENDOR_FAILURE:
      return {
        loaded: false,
        error: action.payload.error,
      };

    case LOAD_VENDOR_DEFAULT:
      return {
        loaded: true,
        error: null,
        customVendor: false,
        ...action.payload,
      };

    case SET_SOCURE_DEVICE_ID:
      return {
        socureDeviceId: action.payload,
        ...state,
      };
    default:
      return state;
  }
};

const userReducer: Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user;

    case AUTH_FAILED:
      return {};

    case LOGOUT:
      return {};

    case REFRESH_USER_STARTED:
      return {
        refreshed: false,
        ...action.payload,
      };
    case REFRESH_USER_SUCCESS:
      return {
        refreshed: true,
        error: null,
        ...action.payload,
      };
    case REFRESH_USER_FAILURE:
      return {
        refreshed: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

const tempReducer: Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_TEMP:
      // unset error and msg properties
      const temp = Object.assign({}, action.data, {
        error: undefined,
        msg: undefined,
      });
      // append new response temp
      return { ...state, ...temp };

    case CLEAR_TEMP:
      // reset temp data
      return {};

    default:
      return state;
  }
};

const dataReducer: Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        deals: action.deals,
      };

    case CLEAR_DATA:
      return {};

    default:
      return state;
  }
};

const getParamsDataReducer: Reducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PARAMS_DATA':
      return { ...state, ...action.partnerCustomFields };

    default:
      return state;
  }
};

const signInTypeReducer: Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_VALIDATON_TYPE:
      return action.signInType;
    default:
      return state;
  }
};

const brandReducerInitialState = {
  data: null,
  loading: false,
  error: null,
};

const brandReducer: Reducer = (state = brandReducerInitialState, action) => {
  switch (action.type) {
    case 'SET_BRAND':
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case 'SET_ROUTES':
      return {
        ...state,
        loading: false,
        data: { ...state.data, routes: action.payload },
        error: false,
      };
    case 'SET_BRAND_FAILURE':
      return {
        data: null,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

const userSellectedToCreateNewDealReducer: Reducer = (state = false, action) => {
  switch (action.type) {
    case 'SET_USER_SELLECTED_TO_CREATE_NEW_DEAL':
      return action.payload;

    default:
      return state;
  }
};

const hiddenRoutesReducer: Reducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_HIDDEN_ROUTES':
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

const routesToHide: Reducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_ROUTES_TO_HIDE':
      return {
        ...state,
        ...action.payload,
        data: {
          ...(action.payload?.data ?? {}),
          ...(action.payload?.data ? { amount: changePriceToNumber(action.payload?.data?.amount) } : {}),
        },
      };
    default:
      return state;
  }
};

const wizardRoutingReducer: Reducer = (
  state = {
    finalWizardRoutes: [],
  },
  action
) => {
  switch (action.type) {
    case 'SET_BRAND':
      return {
        ...state,
        finalWizardRoutes: action.payload.routes,
      };
    case 'SET_ROUTES':
      return {
        ...state,
        finalWizardRoutes: action.payload,
      };
    default:
      return state;
  }
};

const wizardContextReducer: Reducer = (
  state = {
    farthestStep: ApplyWizardStepsWithComponents[STEP_HOMEPAGE],
  },
  action
) => {
  switch (action.type) {
    case 'SET_FARTHEST_STEP':
      return {
        ...state,
        farthestStep: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers<RootState>({
  form: formReducer,
  theme: themeReducer,
  config: configReducer,
  temp: tempReducer,
  wizardContext: wizardContextReducer,
  wizardRouting: wizardRoutingReducer,
  data: dataReducer,
  user: userReducer,
  signInType: signInTypeReducer,
  partnerCustomFields: getParamsDataReducer,
  brand: brandReducer,
  hiddenRoutes: hiddenRoutesReducer,
  userSellectedToCreateNewDeal: userSellectedToCreateNewDealReducer,
  routesToHide: routesToHide,
});
export interface RootState {
  form: FormStateMap;
  theme: {
    primary: string;
    primaryRgba: string;
    secondary: string;
    logoScale: number;
    name: string;
    palette?: {
      error?: { dark: string };
      primary?: { dark: string };
    };
    logoPosition: LogoPositionType;
  };
  wizardRouting: {
    finalWizardRoutes: string[];
  };
  wizardContext: {
    farthestStep: ApplyWizardStepObject;
  };
  config: ConfigType;
  temp: TempType;
  data: any;
  user: UserType['user'];
  signInType: ValidationType;
  partnerCustomFields: { [a: string]: string };
  brand: BrandType;
  hiddenRoutes: string[];
  userSellectedToCreateNewDeal: boolean;
  routesToHide: string[];
}
type BusinessStructureType = 'Corporation' | 'LLC' | 'Partnership' | 'Sole Proprietorship';
type EducationType = 'high_school' | 'associate' | 'bachelors' | 'masters' | 'doctorate' | 'other_grad_degree';
type PayFrequency = 'weekly' | 'biweekly' | 'twice_monthly' | 'monthly';
type EmploymentStatusType =
  | 'self_employed'
  | 'employed_full_time'
  | 'employed_part_time'
  | 'military'
  | 'retired'
  | 'not_employed';
type OwnRentType = 'own_with_mortgage' | 'own_outright' | 'rent';
type CreditRangeType = 'excellent' | 'good' | 'fair' | 'poor' | 'limited';
type StateType =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DE'
  | 'DC'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY';

export type ReduxFormValuesType = {
  loan_type?: LoanType[];
  loan_id?: string;
  business_id?: string;
  business_street?: string;
  business_suite?: string;
  business_city?: string;
  business_state: StateType;
  business_zip?: string;
  owner_1_id?: string;
  owner_1_street?: string;
  owner_1_suite?: string;
  owner_1_city?: string;
  owner_1_state: StateType;
  owner_1_zip?: string;
  owner_2_id?: string;
  owner_2_street?: string;
  owner_2_suite?: string;
  owner_2_city?: string;
  owner_2_state?: StateType;
  owner_2_zip?: string;
  owner_3_id?: string;
  owner_3_street?: string;
  owner_3_suite?: string;
  owner_3_city?: string;
  owner_3_state?: StateType;
  owner_3_zip?: string;
  loan_terms?: string;
  verify_code?: any;
  loan_amount?: string;
  business_ein?: string;
  naics_keyword?: string;
  business_naics?: string;
  business_phone?: string;
  business_revenue?: string;
  business_structure?: BusinessStructureType;
  business_website?: string;
  business_years?: string;
  owner_1_first?: string;
  owner_1_last?: string;
  owner_1_ssn?: string;
  owner_2_first?: string;
  owner_2_last?: string;
  owner_2_ssn?: string;
  owner_3_first?: string;
  owner_3_last?: string;
  owner_3_ssn?: string;
  equipment_description?: string;
  business_name?: string;
  consent: boolean;
  owner_1_mobile?: string;
  owner_1_email?: string;
  owner_2_mobile?: string;
  owner_2_email?: string;
  owner_3_mobile?: string;
  owner_3_email?: string;
  owner_1_dob?: string;
  owner_2_dob?: string;
  owner_3_dob?: string;
  owner_1_title?: string;
  owner_1_ownership?: string;
  verify_method?: ValidationType;
  email?: string;
  mobile?: string;
  credit_range?: CreditRangeType;
  own_rent?: OwnRentType;
  employment_status?: EmploymentStatusType;
  pay_frequency?: PayFrequency;
  annual_income?: string;
  education?: EducationType;
  even_consent?: boolean;
  login_id?: string;
  login_type?: string;
};
