import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setUser } from '../../../redux/actions';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { change } from 'redux-form';
import { Helmet } from 'react-helmet';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { LoadingTento, useHasFullLoaderTimeElapsed } from 'shared-components';
import { postBcmStep1 } from '../../../api/postBcmStep1';

const CreateBcmApplicationContainer = ({
  finalInAppValues,
  reduxUserSaved,
  partnerCustomFields,
  brandId,
  partnerId,
}) => {
  const hasFullAnimationCompleted = useHasFullLoaderTimeElapsed(5000);
  const dispatch = useDispatch();
  const { changeStepAfterLogin } = useApplyWizardContext();
  const data = {
    business: {
      name: finalInAppValues.business_name,
      email: finalInAppValues.owner_1_email,
      phone: finalInAppValues.owner_1_mobile,
      contacts: [
        {
          isMainContact: true,
          firstName: finalInAppValues.owner_1_first,
          lastName: finalInAppValues.owner_1_last,
          email: finalInAppValues.owner_1_email,
          phoneMobile: finalInAppValues.owner_1_mobile,
        },
      ],
    },
    partnerCustomFields,
    brandId,
    partnerId,
  };
  const [{ data: postBcmResponse, loading: postBcmLoading, error: postBcmError }, postBcmFractionalData] =
    postBcmStep1();

  useEffect(() => {
    postBcmFractionalData({ data });
  }, []);

  useEffect(() => {
    if (postBcmResponse?.contactId && postBcmResponse?.accountId && hasFullAnimationCompleted) {
      dispatch(change('application', 'business_id', postBcmResponse.accountId));
      dispatch(change('application', 'owner_1_id', postBcmResponse.contactId));
      dispatch(
        setUser({
          user: {
            ...reduxUserSaved,
            id: postBcmResponse.contactId,
            accountId: postBcmResponse.accountId,
          },
        })
      );
      changeStepAfterLogin();
    }
  }, [postBcmResponse, hasFullAnimationCompleted]);

  return (
    <div className="app-section slide">
      <Helmet>
        <title>Creating Business Credit Monitoring Application</title>
      </Helmet>
      <div className="slideWidth" style={{ marginTop: '16px' }}>
        {postBcmError && (
          <Alert severity="error">
            <AlertTitle>Error Creating Your Application (#00013)</AlertTitle>
            <DataError error={postBcmError} />
          </Alert>
        )}
        {(postBcmLoading || !hasFullAnimationCompleted) && !postBcmError && (
          <div className="flex flex-1 items-center">
            <LoadingTento width={undefined} height={undefined} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
    reduxUserSaved: state.user,
    finalInAppValues: state.form.application?.values,
    routes: state.brand?.data?.routes,
    partnerCustomFields: state.partnerCustomFields,
    vendorId: state.brand?.data?.vendor_id,
    brandId: state.brand?.data?.brand?.id,
    partnerId: state.brand?.data?.brand?.partner_id,
  };
};

export const CreateBcmApplication = connect(mapStateToProps)(CreateBcmApplicationContainer);

export default CreateBcmApplication;
