import React, { FC } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { checkBox, muiCustomSelect, newThemeInput } from '_fields/inputs';
import { lowerCase, words } from '_fields/normalizers';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect, useStore } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { formValueSelector } from 'redux-form';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Spinner } from '@components/Spinner/Spinner';
import { Helmet } from 'react-helmet';
import { Stack, Text } from 'shared-components';
import { NaicsField } from '@components/Apply/MoreDetailsOnYourBusiness/NaicsField';
import { AddressField } from '@components/Apply/MoreDetailsOnYourBusiness/AddressField';
import withOnSubmit from '@components/Apply/withOnSubmit/withOnSubmit';
import {
  ApplyWizardSteps,
  STEP_BCM_BUSINESS_MORE_DETAILS,
  STEP_BUSINESS_MORE_DETAILS,
} from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import ensureLoggedIn from '../../../hooks/ensureLoggedIn';

type MoreDetailsOnYourBusinessProps = {
  loanType: string;
  handleSubmit: () => void;
  invalid: boolean;
  years?: number;
  saving?: boolean;
};

const MoreDetailsOnYourBusinessWrapper: FC<MoreDetailsOnYourBusinessProps> = (props) => {
  const auth0 = useAuth0();

  if (auth0.isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" fullWidth>
        <Spinner />
      </Stack>
    );
  }

  return <MoreDetailsOnYourBusinessMainComponent {...props} />;
};

const structure = ['Corporation', 'LLC', 'Partnership', 'Sole Proprietorship'];

const MoreDetailsOnYourBusinessMainComponent: FC<MoreDetailsOnYourBusinessProps> = ({
  handleSubmit,
  invalid,
  saving: savingOnSubmit,
  years,
}) => {
  const { saving } = useApplyWizardContext();
  const form = useForm();

  const store = useStore();
  const latestValues = getFormValues('application')(store.getState() as RootState) || {};
  const moreYearsValue = years && Number(years) > 20 ? Number(years) : 20; // Not entirely sure why this logic was added before, but I kept it for legacy reasons.
  const validationOutput = reduxFormZodValidate(ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS].zodSchema)(latestValues);

  return (
    <div>
      <Helmet>
        <title>More Details on Your Business</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="More Details on Your Business" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
                <Field
                  name="business_dba"
                  component={newThemeInput}
                  type="text"
                  placeholder="Doing Business As (Optional)"
                  normalize={words}
                  label="Doing Business As (Optional)"
                  data-testid="business_dba"
                  autoFocus
                />
                <AddressField autoFocus={false} />
                {(latestValues as any).business_city && (validationOutput as any).business_street && (
                  <Text variant="error">Please enter a valid street address</Text>
                )}

                <Field
                  data-testid="fed-tax-id"
                  name="business_ein"
                  component={newThemeInput}
                  type="tel"
                  label="Tax ID"
                  placeholder="Federal Tax ID* (XX-XXXXXXX)"
                  formatAsFederalTaxId
                />
                <Field
                  data-testid={`owner-1-title`}
                  name={`owner_1_title`}
                  component={newThemeInput}
                  type="text"
                  normalize={words}
                  label="Your Position in Company*"
                />
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={
                    invalid || (validationOutput as any).business_street || (validationOutput as any).business_city
                  }
                  saving={saving || savingOnSubmit}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const MoreDetailsOnYourBusinessWrapper2 = reduxForm<MoreDetailsOnYourBusinessProps, MoreDetailsOnYourBusinessProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_BCM_BUSINESS_MORE_DETAILS].zodSchema),
})(MoreDetailsOnYourBusinessWrapper);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    years: appSelector(state, 'business_years'),
  };
};

export const BCMMoreDetailsOnYourBusiness = ensureLoggedIn(
  withOnSubmit(connect(mapStateToProps)(MoreDetailsOnYourBusinessWrapper2), STEP_BCM_BUSINESS_MORE_DETAILS)
);
