import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setUser } from '../../../redux/actions';
import { backendBaseUrl, contactEmail } from '../../../utils/platformBasedInfo';
import { getLoaneeDeals } from '../../../api/getLoaneeDeals';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { PickDealTable } from '../../PickDealTable/PickDealTable';
import { getLoaneeDeal } from '../../../api/getLoaneeDeal';
import { prefillReduxStateFromDealResponse } from '@components/Apply/SelectApplication/prefillReduxStateFromDealResponse';
import { Helmet } from 'react-helmet';
import { Loader, Separator, Stack, Text, capitalizeFirstLetter } from 'shared-components';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { change, reset, formValueSelector } from 'redux-form';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingTento } from 'shared-components';

const SelectApplicationContainer = ({
  routes,
  user,
  owner1First,
  config,
  reduxUserSaved,
  userSellectedToCreateNewDeal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deals, setDeals] = useState();
  const [selectedDealId, setSelectedDealId] = useState();
  const { resetFarthestStep, changeStepToFirst } = useApplyWizardContext();
  const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const [{ data: dealsResponse, loading: dealsLoading, error: dealsError }, getDeals] = getLoaneeDeals();
  const [{ data: entireDealDataResponse, loading: entireDealLoading, error: entireDealError }, getEntireDeal] =
    getLoaneeDeal();

  useEffect(() => {
    getDeals();
  }, []);

  useEffect(() => {
    if (!dealsResponse) {
      return;
    }
    setDeals(dealsResponse.deals);
  }, [dealsResponse]);

  useEffect(() => {
    if (selectedDealId === null || selectedDealId === undefined) {
      return;
    }

    getEntireDeal({
      url: `${backendBaseUrl}/deals/loanee/${selectedDealId}`,
    });
  }, [selectedDealId]);

  useEffect(() => {
    if (entireDealDataResponse) {
      setIsLoading(true);
      const accountId = entireDealDataResponse?.business?.id;
      const dealId = entireDealDataResponse?.id ?? entireDealDataResponse?.loanRequest?.id;
      const data = {
        user: {
          ...reduxUserSaved,
          accountId: entireDealDataResponse?.business?.id,
        },
      };
      prefillReduxStateFromDealResponse({
        dispatch,
        config,
        user: data.user,
        deal: entireDealDataResponse,
      });
      dispatch(setUser(data));
      dispatch(change('application', 'business_id', accountId));
      dispatch(change('application', 'loan_id', dealId));
      resetFarthestStep();
      setTimeout(() => {
        changeStepToFirst();
        setIsLoading(false);
      }, 500);
    }
  }, [entireDealDataResponse]);

  useEffect(() => {
    if (!user || !user.loggedIn) {
      loginWithRedirect({
        appState: {
          returnTo: '/app/verify/login',
        },
      });
    }
  }, [user]);

  if (!user || !user.loggedIn) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Select Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="after-separator" textAlign="center" justifyContent="start">
        <Stack gapVariant="before-separator" textAlign="center">
          <Stack gapVariant="headline-to-subheadline" textAlign="center">
            <div>
              <div>
                <Text variant="headline-lg">Welcome back {capitalizeFirstLetter(owner1First ?? user.name ?? '')}</Text>
              </div>
            </div>
            <div>
              <Text variant="subheadline-lg">
                View your incomplete applications and pick up right where you left off. If you have any questions about
                a submitted application, feel free to reach out to us at{' '}
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
              </Text>
            </div>
          </Stack>
          <Separator />
        </Stack>
        <Text variant="headline-sm">Explore Applications</Text>
        {dealsError && (
          <Alert severity="error">
            <AlertTitle>Error Loading Your Applications</AlertTitle>
            <DataError error={dealsError} />
          </Alert>
        )}
        {entireDealError && (
          <Alert severity="error">
            <AlertTitle>Error Loading Selected Application</AlertTitle>
            <DataError error={entireDealError} />
          </Alert>
        )}
        {(dealsLoading || entireDealLoading) && !dealsError && !entireDealError && (
          <div className="flex flex-1 items-center">
            <Loader />
          </div>
        )}
        {!dealsLoading && userSellectedToCreateNewDeal !== true && (
          // Deal(s) attached to user account(s)
          // Let the user choose: one of the existing deals OR create a new one.
          <PickDealTable
            onDealPicked={(deal) => setSelectedDealId(deal.id)}
            onNewDealPicked={() => {
              setIsLoading(true);
              dispatch(reset('application'));
              dispatch(change('application', 'application_type', 'apply'));
              dispatch(change('application', 'loan_amount', 150000));
              dispatch(change('application', 'loan_terms', 60));
              dispatch(change('application', 'owner_1_email', user.loggedIn ? user.email : ''));
              resetFarthestStep();
              changeStepToFirst();
              setIsLoading(false);
            }}
            deals={deals ?? []}
            isLoading={dealsLoading || entireDealLoading || isLoading}
          />
        )}
      </Stack>
    </>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    owner1First: selector(state, 'owner_1_first'),
    user: state.user,
    config: state.config,
    reduxUserSaved: state.user,
    finalInAppValues: state.form.application?.values,
    routes: state.brand?.data?.routes,
    userSellectedToCreateNewDeal: state.userSellectedToCreateNewDeal,
  };
};

export const SelectApplicationRoute = connect(mapStateToProps)(SelectApplicationContainer);

export default SelectApplicationRoute;
