import { HomeWrapped } from '@components/Home';
import AmountContainer from '@components/Apply/Amount';
import Auth0CallbackContainer from '@components/Apply/Auth0';
import { MoreDetailsOnYourBusiness } from '@components/Apply/MoreDetailsOnYourBusiness/MoreDetailsOnYourBusiness';
import { FurtherBusinessDetails } from '@components/Apply/FurtherBusinessDetails/FurtherBusinessDetails';
import { OwnersMoreDetailsAboutYou } from '@components/Apply/OwnersMoreDetailsAboutYou/OwnersMoreDetailsAboutYou';
import { FundingIsAlmostHere } from '@components/Apply/FundingIsAlmostHere/FundingIsAlmostHere';
import { TypeOfFunding } from '@components/Apply/TypeOfFunding/TypeOfFunding';
import { WhenFundingNeeded } from '@components/Apply/WhenFundingNeeded/WhenFundingNeeded';
import { BCMMoreDetailsOnYourBusiness } from '@components/Apply/MoreDetailsOnYourBusiness/BCMMoreDetailsOnYourBusiness';
import { BCMSummary } from '@components/Apply/BCMSummary/BCMSummary';
import InfoContainer from '@components/Apply/Info';
import BCMHome from '@components/BCM/BCMHome';
import { BankConnectContainer } from '@components/Apply/BankConnect/BankConnect';
import {
  ApplyWizardStep,
  ApplyWizardStepObject,
  STEP_AMOUNT,
  STEP_BUSINESS_FURTHER_DETAILS,
  STEP_BUSINESS_MORE_DETAILS,
  STEP_BUSINESS_OWNERS,
  STEP_HOMEPAGE,
  STEP_BCM_HOMEPAGE,
  STEP_INFO,
  STEP_SUMMARY,
  STEP_TYPE_OF_FUNDING,
  STEP_VERIFY_LOGIN,
  STEP_WHEN_FUNDING_NEEDED,
  STEP_BCM_INFO,
  STEP_BCM_BUSINESS_MORE_DETAILS,
  STEP_BCM_SUMMARY,
  ApplyWizardSteps,
  STEP_BANK_CONNECT,
} from '@components/ApplyWizard/ApplyWizardSteps';

export const ApplyWizardStepsWithComponents: Record<ApplyWizardStep, ApplyWizardStepObject> = {
  [STEP_HOMEPAGE]: {
    ...ApplyWizardSteps[STEP_HOMEPAGE],
    Component: HomeWrapped,
  },
  [STEP_AMOUNT]: {
    Component: AmountContainer,
    ...ApplyWizardSteps[STEP_AMOUNT],
  },
  [STEP_INFO]: {
    Component: InfoContainer,
    ...ApplyWizardSteps[STEP_INFO],
  },
  [STEP_VERIFY_LOGIN]: {
    Component: Auth0CallbackContainer,
    ...ApplyWizardSteps[STEP_VERIFY_LOGIN],
  },
  [STEP_BCM_HOMEPAGE]: {
    Component: BCMHome,
    ...ApplyWizardSteps[STEP_BCM_HOMEPAGE],
  },
  [STEP_BCM_SUMMARY]: {
    Component: BCMSummary,
    ...ApplyWizardSteps[STEP_BCM_SUMMARY],
  },
  [STEP_BCM_INFO]: {
    Component: InfoContainer,
    ...ApplyWizardSteps[STEP_BCM_INFO],
  },
  [STEP_BCM_BUSINESS_MORE_DETAILS]: {
    Component: BCMMoreDetailsOnYourBusiness,
    ...ApplyWizardSteps[STEP_BCM_BUSINESS_MORE_DETAILS],
  },
  [STEP_BUSINESS_MORE_DETAILS]: {
    Component: MoreDetailsOnYourBusiness,
    ...ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS],
  },
  [STEP_BANK_CONNECT]: {
    Component: BankConnectContainer,
    ...ApplyWizardSteps[STEP_BANK_CONNECT],
  },
  [STEP_BUSINESS_FURTHER_DETAILS]: {
    Component: FurtherBusinessDetails,
    ...ApplyWizardSteps[STEP_BUSINESS_FURTHER_DETAILS],
  },
  [STEP_BUSINESS_OWNERS]: {
    Component: OwnersMoreDetailsAboutYou,
    ...ApplyWizardSteps[STEP_BUSINESS_OWNERS],
  },
  [STEP_TYPE_OF_FUNDING]: {
    Component: TypeOfFunding,
    ...ApplyWizardSteps[STEP_TYPE_OF_FUNDING],
  },
  [STEP_WHEN_FUNDING_NEEDED]: {
    Component: WhenFundingNeeded,
    ...ApplyWizardSteps[STEP_WHEN_FUNDING_NEEDED],
  },
  [STEP_SUMMARY]: {
    Component: FundingIsAlmostHere,
    ...ApplyWizardSteps[STEP_SUMMARY],
  },
};
