import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setUser } from '../../../redux/actions';
import { backendBaseUrl, contactEmail, voltBaseUrl } from '../../../utils/platformBasedInfo';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { PickDealTable } from '../../PickDealTable/PickDealTable';
import { getLoaneeDeal } from '../../../api/getLoaneeDeal';
import {
  prefillReduxStateFromAccountForBCM,
  prefillReduxStateFromDealResponse,
} from '@components/Apply/SelectApplication/prefillReduxStateFromDealResponse';
import { Helmet } from 'react-helmet';
import { Loader, Separator, Stack, Text, capitalizeFirstLetter } from 'shared-components';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { change, reset, formValueSelector } from 'redux-form';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingTento } from 'shared-components';
import { BcmAccountListItemType, getUserAccounts, UserAccountsResponseType } from '../../../api/getUserAccounts';
import { RootState } from '@store/reducers';
import { PickAccountTable } from '@components/PickAccountTable/PickAccountTable';

const SelectBCMApplicationContainer = ({
  user,
  owner1First,
  config,
  reduxUserSaved,
}: {
  owner1First: string;
  reduxUserSaved: RootState['user'];
  config: RootState['config'];
  user: RootState['user'];
}) => {
  const dispatch = useDispatch();
  const [businesses, setBusinesses] = useState<BcmAccountListItemType[]>();
  const { resetFarthestStep, changeStepToFirst } = useApplyWizardContext();
  const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [{ data: accountsResponse, loading: accountsLoading, error: accountsError }, getAccounts] = getUserAccounts();

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (!accountsResponse) {
      return;
    }
    setBusinesses((accountsResponse as UserAccountsResponseType).businesses);
  }, [accountsResponse]);

  useEffect(() => {
    if (!user || !user.loggedIn) {
      loginWithRedirect({
        appState: {
          returnTo: '/app/verify/login',
        },
      });
    }
  }, [user]);

  if (!user || !user.loggedIn) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Select Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="after-separator" textAlign="center" justifyContent="start">
        <Stack gapVariant="before-separator" textAlign="center">
          <Stack gapVariant="headline-to-subheadline" textAlign="center">
            <div>
              <div>
                <Text variant="headline-lg">Welcome back {capitalizeFirstLetter(owner1First ?? user.name ?? '')}</Text>
              </div>
            </div>
            <div>
              <Text variant="subheadline-lg">
                View your incomplete Credit Monitoring applications and pick up right where you left off. If you have
                any questions about a submitted application, feel free to reach out to us at{' '}
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
              </Text>
            </div>
          </Stack>
          <Separator />
        </Stack>
        <Text variant="headline-sm">Explore Applications</Text>
        {accountsError && (
          <Alert severity="error">
            <AlertTitle>Error Loading Your Applications</AlertTitle>
            <DataError error={accountsError} />
          </Alert>
        )}
        {accountsLoading && !accountsError && (
          <div className="flex flex-1 items-center">
            <Loader />
          </div>
        )}
        {!accountsLoading && (
          // Deal(s) attached to user account(s)
          // Let the user choose: one of the existing deals OR create a new one.
          <PickAccountTable
            onAccountPicked={(account) => {
              if (account.bcmStatus.startsWith('approved')) {
                window.location.href = `${voltBaseUrl}`;
              } else {
                setIsLoading(true);
                const accountId = account.id;
                const data = {
                  user: {
                    ...reduxUserSaved,
                    accountId: account?.id,
                  },
                };
                prefillReduxStateFromAccountForBCM({
                  dispatch,
                  config,
                  user: data.user,
                  account: account,
                });
                dispatch(setUser(data));
                dispatch(change('application', 'business_id', accountId));
                if (resetFarthestStep) {
                  resetFarthestStep();
                }
                setTimeout(() => {
                  if (changeStepToFirst) {
                    changeStepToFirst();
                  }
                  setIsLoading(false);
                }, 500);
              }
            }}
            onNewAccountPicked={() => {
              setIsLoading(true);
              dispatch(reset('application'));
              dispatch(change('application', 'application_type', 'apply'));
              dispatch(change('application', 'owner_1_email', user.loggedIn ? user.email : ''));
              if (resetFarthestStep) {
                resetFarthestStep();
              }
              if (changeStepToFirst) {
                changeStepToFirst();
              }
              setIsLoading(false);
            }}
            accounts={businesses ?? []}
            isLoading={accountsLoading || isLoading}
          />
        )}
      </Stack>
    </>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    owner1First: selector(state, 'owner_1_first'),
    user: state.user,
    config: state.config,
    reduxUserSaved: state.user,
    finalInAppValues: state.form.application?.values,
    routes: state.brand?.data?.routes,
  };
};

export const SelectBCMApplication = connect(mapStateToProps)(SelectBCMApplicationContainer);

export default SelectBCMApplication;
