import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RootState } from '@store/reducers';
import { Helmet } from 'react-helmet';
import { Stack, capitalizeFirstLetter } from 'shared-components';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { contactEmail } from '@utils/platformBasedInfo';

const EndAsWillContact = (
  props: PropsWithChildren<{
    theme: any;
    first?: string;
    businessName?: string;
  }>
) => {
  return (
    <div className="app-section slide">
      <Helmet>
        <title>Credit Monitoring Requested</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart
          headingText="Credit Monitoring Requested"
          subHeadingText={
            <>
              Thank you{props.first ? ` ${capitalizeFirstLetter(props.first)}` : ''}, we’ve received your application to
              enable credit monitoring{props.businessName ? ` for ${props.businessName}` : ''}. You can expect to
              receive another update within 1-2 business days, but if you need support sooner please email us at{' '}
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
            </>
          }
          subheadingProps={{ className: 'max-w-[684px]' }}
        />
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    first: selector(state, 'owner_1_first'),
    businessName: selector(state, 'business_name'),
    theme: state.theme,
  };
};

export const BCMEndAsWillContact = connect(mapStateToProps)(EndAsWillContact);
