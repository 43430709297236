import React, { FC } from 'react';
import { reduxForm, ChangeAction } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RootState } from '@store/reducers';
import Address from '@components/_shared/Address/Address';

type AddressFieldProps = {
  theme: RootState['theme'];
  change: ChangeAction;
  street?: string;
  suite?: string;
  city?: string;
  state?: string;
  zip?: string;
  autoFocus?: boolean;
};

const AddressFieldInternal2: FC<AddressFieldProps> = ({
  theme,
  change,
  street,
  suite,
  city,
  state,
  zip,
  autoFocus = true,
}) => {
  const address = {
    street: street,
    suite: suite,
    city: city,
    state: state,
    zip: zip,
  };
  return (
    <Address
      address={address}
      change={change}
      showDefaultManualAddress={(city?.length ?? 0) > 0 && (street?.length ?? 0) > 0 && (state?.length ?? 0) > 0}
      ownerIdx={null}
      autoFocus={autoFocus}
    />
  );
};

const AddressFieldInternal1 = reduxForm<Omit<AddressFieldProps, 'change'>, Omit<AddressFieldProps, 'change'>>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AddressFieldInternal2);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    theme: state.theme,
    street: appSelector(state, 'business_street'),
    suite: appSelector(state, 'business_suite'),
    city: appSelector(state, 'business_city'),
    state: appSelector(state, 'business_state'),
    zip: appSelector(state, 'business_zip'),
  };
};

export const AddressField = connect(mapStateToProps)(AddressFieldInternal1);
