import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues, change } from 'redux-form';
import { withRouter, Switch, Route, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '@components/_shared/Auth/PrivateRoute';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { EmitterProvider, Emitter } from 'react-emitter';
import { setUser } from '@store/actions';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorBoundary from '@components/_shared/Error/Error';
import Alert from '@components/_shared/Alert/Alert';

import ContinueByDealIdContainer from '@components/Apply/Continue';
import ContinuePlaidConnectOnlyContainer from '@components/Apply/Continue/ContinuePlaidConnectOnly';
import ContinueDirectUploadOnlyContainer from '@components/Apply/Continue/ContinueDirectUploadOnly';
import LoanContainer from '@components/Apply/Loan';
import AmountContainer from '@components/Apply/Amount';
import EquipmentContainer from '@components/Apply/Equipment';
import InfoContainer from '@components/Apply/Info';
import BusinessContainer from '@components/Apply/Business';
import OwnersContainer from '@components/Apply/Owners';
import DecisionContainer from '@components/Apply/Decision';
import BankContainer from '@components/Apply/Bank';
import SummaryContainer from '@components/Apply/Summary/Summary';
import AgreementContainer from '@components/Apply/Agreement';
import Done from '@components/Apply/Done/Done';
import Auth0CallbackContainer from './Auth0';
import SelectApplication from '@components/Apply/SelectApplication/SelectApplication';
import CreateApplication from '@components/Apply/CreateApplication/CreateApplication';
import { MoreDetailsOnYourBusiness } from '@components/Apply/MoreDetailsOnYourBusiness/MoreDetailsOnYourBusiness';
import { FurtherBusinessDetails } from '@components/Apply/FurtherBusinessDetails/FurtherBusinessDetails';
import { OwnersMoreDetailsAboutYou } from '@components/Apply/OwnersMoreDetailsAboutYou/OwnersMoreDetailsAboutYou';
import { FundingIsAlmostHere } from '@components/Apply/FundingIsAlmostHere/FundingIsAlmostHere';
import { TypeOfFunding } from '@components/Apply/TypeOfFunding/TypeOfFunding';
import { WhenFundingNeeded } from '@components/Apply/WhenFundingNeeded/WhenFundingNeeded';

import { ParamsFromUrlWrapper } from '../../utils/ParamsFromUrlWrapper';
import { cn } from '@utils/cn';
import UserExpiredRoute from '@components/User/Expired';
import EndAsWillContact from '@components/EndAsWillContact/EndAsWillContact';
import { HomeWrapped } from '@components/Home';
import { BankConnectContainer } from '@components/Apply/BankConnect/BankConnect';
import { useApplyWizardContext } from '../../context/ApplyWizardContext';
import { BankConnectOrUploadContainer } from '@components/Apply/BankConnectOrUpload/BankConnectOrUpload';
import { Contact } from '@components/Apply/contact/Contact';

const Application = ({
  match,
  history,
  dispatch,
  user,
  allValues,
  location,
  routes,
  loan_type,
  loan_terms,
  change,
  theme,
  images,
  vendor_name,
  business_id,
  contact_id,
  config,
  loan_id,
  partnerCustomFields,
}) => {
  const {
    nextRoute,
    callApi,
    dataLayerPush,
    showProcessingModal,
    saving,
    setSaving,
    openAlert,
    closeAlert,
    alertOpen,
    alertMsg,
    alertLevel,
    alertProcessing,
    alertFeedbackLink,
  } = useApplyWizardContext();
  let { path, url } = useRouteMatch();

  return (
    <main className={cn(theme.name, 'flex-1')}>
      <ErrorBoundary>
        <Switch location={location}>
          {/* --- Moved to Wizard and managed in ApplyWizardContext --- */}
          <Route exact path={'/contact'} render={() => <Contact />} />
          <Route exact path={'/app/amount'} render={() => <AmountContainer />} />
          <Route exact path={'/app/info'} render={() => <InfoContainer />} />
          <Route path={'/app/verify/login'} render={() => <Auth0CallbackContainer />} />
          <PrivateRoute exact path={'/app/business/more-details'} component={MoreDetailsOnYourBusiness} />
          <PrivateRoute exact path={'/app/business/further-details'} component={FurtherBusinessDetails} />
          <PrivateRoute exact path={'/app/business/owners'} component={OwnersMoreDetailsAboutYou} />
          <PrivateRoute exact path={'/app/type-of-funding'} component={TypeOfFunding} />
          <PrivateRoute exact path={'/app/aproximate-timing'} component={WhenFundingNeeded} />
          <PrivateRoute exact path={'/app/funding-is-almost-here'} component={FundingIsAlmostHere} />
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <Route exact path={'/app/user/expired'} component={UserExpiredRoute} />
          <Route path={'/app/select'} render={() => <SelectApplication />} />
          <Route path={'/app/create'} render={() => <CreateApplication />} />
          <Route path={'/app/loan/:type?'} render={() => <LoanContainer saving={saving} nextRoute={nextRoute} />} />

          <Route
            path={'/continue/direct-upload/:dealId/:accountId/:mainContactId?'}
            render={() => {
              return (
                <ContinueDirectUploadOnlyContainer
                  saving={saving}
                  nextRoute={nextRoute}
                  location={location}
                  dataLayerPush={dataLayerPush}
                />
              );
            }}
          />
          <Route
            path={'/continue/plaid-connect/:dealId/:accountId/:mainContactId?'}
            render={() => (
              <ContinuePlaidConnectOnlyContainer
                saving={saving}
                nextRoute={nextRoute}
                location={location}
                dataLayerPush={dataLayerPush}
              />
            )}
          />
          <Route
            path={'/continue/direct-upload'}
            render={() => {
              return (
                <ContinueDirectUploadOnlyContainer
                  saving={saving}
                  nextRoute={nextRoute}
                  location={location}
                  dataLayerPush={dataLayerPush}
                />
              );
            }}
          />
          <Route
            path={'/continue/plaid-connect'}
            render={() => (
              <ContinuePlaidConnectOnlyContainer
                saving={saving}
                nextRoute={nextRoute}
                location={location}
                dataLayerPush={dataLayerPush}
              />
            )}
          />
          <Route
            path={'/continue/plaid'}
            render={() => (
              <BankConnectOrUploadContainer
                isPlaidOnly={true}
                customFieldName="extraDocuments"
                source="apply-upload-url"
              />
            )}
          />
          <Route
            path={'/continue/upload'}
            render={() => (
              <BankConnectOrUploadContainer
                isUploadOnly={true}
                customFieldName="extraDocuments"
                source="apply-upload-url"
              />
            )}
          />
          <Route
            path={'/continue/plaid-and-upload'}
            render={() => <BankConnectOrUploadContainer customFieldName="extraDocuments" source="apply-upload-url" />}
          />
          <Route
            path={'/app/continue/:dealId?'}
            render={() => <ContinueByDealIdContainer saving={saving} nextRoute={nextRoute} location={location} />}
          />

          <Route
            exact
            path={'/app/equipment'}
            render={() => <EquipmentContainer saving={saving} setSaving={setSaving} nextRoute={nextRoute} />}
          />
          <Route exact path={'/app/contact-soon'} render={() => <EndAsWillContact />} />

          <PrivateRoute
            exact
            path={'/app/business/:property'}
            component={BusinessContainer}
            callApi={callApi}
            saving={saving}
            setSaving={setSaving}
            nextRoute={nextRoute}
            dataLayerPush={dataLayerPush}
            loanType={loan_type}
          />

          <PrivateRoute
            exact
            path={'/app/owners/:idx/:property'}
            component={OwnersContainer}
            callApi={callApi}
            saving={saving}
            nextRoute={nextRoute}
            closeAlert={closeAlert}
            dataLayerPush={dataLayerPush}
            loanType={loan_type}
          />

          <PrivateRoute
            exact
            path={'/app/bank-connect'}
            component={BankConnectContainer}
            nextRoute={nextRoute}
            saving={saving}
            callApi={callApi}
            setSaving={setSaving}
            openAlert={openAlert}
            closeAlert={closeAlert}
            dataLayerPush={dataLayerPush}
            showProcessingModal={showProcessingModal}
          />

          <PrivateRoute
            exact
            path={'/app/bank/:property'}
            component={BankContainer}
            saving={saving}
            callApi={callApi}
            setSaving={setSaving}
            openAlert={openAlert}
            closeAlert={closeAlert}
            dataLayerPush={dataLayerPush}
            showProcessingModal={showProcessingModal}
          />

          <PrivateRoute
            exact
            path={'/app/decision/:property'}
            component={DecisionContainer}
            callApi={callApi}
            saving={saving}
            setSaving={setSaving}
            nextRoute={nextRoute}
            closeAlert={closeAlert}
            dataLayerPush={dataLayerPush}
            loanType={loan_type}
          />

          <PrivateRoute
            exact
            path={'/app/agreement/:property'}
            component={AgreementContainer}
            saving={saving}
            callApi={callApi}
            nextRoute={nextRoute}
            setSaving={setSaving}
            openAlert={openAlert}
            closeAlert={closeAlert}
            dataLayerPush={dataLayerPush}
            showProcessingModal={showProcessingModal}
          />

          <PrivateRoute
            exact
            path={'/app/summary'}
            vendor_name={vendor_name}
            callApi={callApi}
            component={SummaryContainer}
            dataLayerPush={dataLayerPush}
          />

          <PrivateRoute exact path={'/app/done'} component={Done} dataLayerPush={dataLayerPush} />
          <Route exact path={'/'} render={() => <HomeWrapped />} />
        </Switch>
      </ErrorBoundary>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alertOpen}
        onClose={closeAlert}
      >
        <Alert
          onClose={closeAlert}
          variant={alertLevel}
          className={alertLevel}
          message={alertMsg}
          showProcessing={alertProcessing}
          showFeedbackLink={alertFeedbackLink}
        />
      </Snackbar>
    </main>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  // attempt to ensure business_id is set
  const bizId = selector(state, 'business_id');
  const acctId = bizId ? bizId : state.user.accountId;

  return {
    routes: state.brand?.data?.routes,
    vendor_name: state.brand?.data?.vendor_name,
    business_id: acctId,
    loan_id: selector(state, 'loan_id'),
    loan_type: selector(state, 'loan_type'),
    contact_id: selector(state, 'owner_1_id'),
    loan_terms: selector(state, 'loan_terms'),
    allValues: getFormValues('application')(state) || {},
    theme: state.theme,
    config: state.config,
    temp: state.temp,
    user: state.user,
    images: state.brand?.data?.media,
    partnerCustomFields: state.partnerCustomFields,
  };
};

const ApplicationConnected = connect(mapStateToProps, { setUser, change })(Application);

const ApplicationConnectedWithEmitter = Emitter(ApplicationConnected);

const ApplicationWrapped = withRouter(EmitterProvider(ApplicationConnectedWithEmitter));

export default ApplicationWrapped;
