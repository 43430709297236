import React from 'react';

import { connect } from 'react-redux';
import { formValueSelector, getFormValues, change } from 'redux-form';
import { withRouter, Switch, Route as RouteRouter, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '@components/_shared/Auth/PrivateRoute';
import { EmitterProvider, Emitter } from 'react-emitter';
import { setUser } from '@store/actions';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorBoundary from '@components/_shared/Error/Error';
import Alert from '@components/_shared/Alert/Alert';
import AmountContainer from '@components/Apply/Amount';
import InfoContainer from '@components/Apply/Info';
import { SelectBCMApplication } from '@components/Apply/SelectApplication/SelectBCMApplication';
import { CreateBcmApplication } from '@components/Apply/CreateBcmApplication/CreateBcmApplication';
import { cn } from '@utils/cn';
import { useApplyWizardContext } from '../../context/ApplyWizardContext';
import { Contact } from '@components/Apply/contact/Contact';
import { BCMMoreDetailsOnYourBusiness } from '@components/Apply/MoreDetailsOnYourBusiness/BCMMoreDetailsOnYourBusiness';
import { BCMEndAsWillContact } from '@components/BCM/BCMEndAsWillContact';
import { BCMSummary } from '@components/Apply/BCMSummary/BCMSummary';
import { BCMHome } from '@components/BCM/BCMHome';
import { RootState } from '@store/reducers';

const Route = RouteRouter as any;

const BCMRouterInner = ({ location, theme }: { location: any; theme: any }) => {
  const {
    nextRoute,
    callApi,
    dataLayerPush,
    showProcessingModal,
    saving,
    setSaving,
    openAlert,
    closeAlert,
    alertOpen,
    alertMsg,
    alertLevel,
    alertProcessing,
    alertFeedbackLink,
  } = useApplyWizardContext();
  let { path, url } = useRouteMatch();

  // console.log('BCMRouterInner', location, theme, vendor_name);
  return (
    <main className={cn(theme.name, 'flex-1')}>
      <ErrorBoundary>
        <Switch location={location}>
          <Route exact path={'/bcm/contact'} render={() => <Contact />} />
          <Route exact path={'/bcm/app/amount'} render={() => <AmountContainer />} />
          <Route exact path={'/bcm/app/info'} render={() => <InfoContainer />} />
          <PrivateRoute exact path={'/bcm/app/business/more-details'} component={BCMMoreDetailsOnYourBusiness} />
          <Route path={'/bcm/business/select'} render={() => <SelectBCMApplication />} />
          <Route path={'/bcm/app/create'} render={() => <CreateBcmApplication />} />
          <Route exact path={'/bcm/app/contact-soon'} render={() => <BCMEndAsWillContact />} />
          <PrivateRoute exact path={'/bcm/app/summary'} component={() => <BCMSummary />} />
          <Route exact path={'/bcm'} render={() => <BCMHome />} />
        </Switch>
      </ErrorBoundary>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alertOpen}
        onClose={closeAlert}
      >
        <Alert
          onClose={closeAlert}
          variant={alertLevel ?? 'info'}
          className={alertLevel}
          message={alertMsg}
          showProcessing={alertProcessing}
          showFeedbackLink={alertFeedbackLink}
        />
      </Snackbar>
    </main>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  // attempt to ensure business_id is set
  const bizId = selector(state, 'business_id');
  const acctId = bizId ? bizId : state.user.accountId;

  return {
    routes: state.brand?.data?.routes,
    vendor_name: state.brand?.data?.vendor_name,
    business_id: acctId,
    contact_id: selector(state, 'owner_1_id'),
    allValues: getFormValues('application')(state) || {},
    theme: state.theme,
    config: state.config,
    temp: state.temp,
    user: state.user,
    images: state.brand?.data?.media,
    partnerCustomFields: state.partnerCustomFields,
  };
};

const ApplicationConnected = connect(mapStateToProps, { setUser, change })(BCMRouterInner);

const ApplicationConnectedWithEmitter = Emitter(ApplicationConnected);

const ApplicationWrapped = withRouter(EmitterProvider(ApplicationConnectedWithEmitter));

export const BCMRouter = ApplicationWrapped;
export default ApplicationWrapped;
