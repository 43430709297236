import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { loadDefaultVendor, logout } from '@store/actions';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth0 } from '@auth0/auth0-react';
import { LaunchDarklyWrapper } from '@components/LaunchDarklyWrapper/LaunchDarklyWrapper';
import { Auth0LoggedInSetState } from './Auth0LoggedInSetState';
import { Loader, LoadingTento } from 'shared-components';
import { useApplyWizardContext } from './context/ApplyWizardContext';
import { STEP_BCM_HOMEPAGE, STEP_HOMEPAGE } from '@components/ApplyWizard/ApplyWizardSteps';
import { ParamsFromUrlWrapper } from '@utils/ParamsFromUrlWrapper';
import { motion, AnimatePresence } from 'framer-motion';
import { isSyntheticTest } from '@utils/platformBasedInfo';

const Apply = lazy(() => import('./components/Apply'));
const BCM = lazy(() => import('./components/BCM'));
const Version = lazy(() => import('./Version'));

const UserCleanup = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  useEffect(() => {
    if (!auth0.isLoading && !auth0.isAuthenticated) {
      dispatch(logout());
    }
  }, [auth0.isAuthenticated, auth0.isLoading]);
  return null;
};

const BrandHomeComponent = ({ route_home }) => {
  return route_home ? (
    <Route exact path="/">
      <Redirect to={route_home} />
    </Route>
  ) : (
    <Route exact path="/" component={Apply} />
  );
};

export const auth0AuthroizationParams = {
  redirect_uri: window.location.origin,
  audience: encodeURI('https://backend.tento.co'),
};

const AppView = (props) => {
  const { currentPath, callApi, dataLayerPush } = useApplyWizardContext();
  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(document.location.search);
  const subId = searchParams.get('sub_id');
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading } = useAuth0();

  const appRouteDefault = '/app/:step/:property?';
  const appRouteIndexed = '/app/:step/:idx/:property';

  // load default config
  useEffect(() => {
    dispatch(loadDefaultVendor());
  }, []);

  useEffect(() => {
    // Segment Page Data
    const pageEventProps = {
      brand: {
        brandId: props?.brand_params?.id || '',
        brandName: props?.brand_params?.name || '',
        partnerId: props?.brand_params?.partner_id || '',
        partnerName: props?.brand_params?.partner_name || '',
      },
    };
    // console.log('page view', pageEventProps);
    if (!isSyntheticTest) {
      analytics.page({ ...pageEventProps });
    }
  }, [location, subId]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const a = await getAccessTokenSilently();
        history.push('/app/select');
      } catch (error) {}
    };

    if (!isAuthenticated && !isLoading && window.IS_STORYBOOK !== '1') {
      checkSession();
    }
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading]);

  return (
    <>
      <AnimatePresence initial={false}>
        {(currentPath === STEP_HOMEPAGE ||
          currentPath === STEP_BCM_HOMEPAGE ||
          (window.IS_STORYBOOK === '1' &&
            location.pathname === STEP_HOMEPAGE &&
            location.pathname === STEP_BCM_HOMEPAGE)) && (
          <motion.div
            className={'apply-background-content h-screen w-[100vw] absolute'}
            transition={{ duration: 2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      {process.env.NODE_ENV !== 'test' && !window.IS_STORYBOOK && <UserCleanup />}
      <motion.div className={'bg-dawn h-screen apply'}>
        <Auth0LoggedInSetState />
        <LaunchDarklyWrapper>
          <div>
            <Switch>
              <Suspense
                fallback={
                  <div className="flex flex-1 items-center">
                    <Loader />
                  </div>
                }
              >
                <ParamsFromUrlWrapper callApi={callApi} dataLayerPush={dataLayerPush}>
                  <Route exact path={'/contact'} component={Apply} />
                  <Route exact path={'/version'} component={Version} />
                  <Route exact path={'/app'} component={Apply} />
                  <Route exact path={'/bcm'} component={BCM} />
                  <Route exact path={'/bcm/business/select'} component={BCM} />
                  <Route exact path={'/bcm/app/:step/:property?'} component={BCM} />
                  <Route exact path={'/bcm/app/:step/:idx/:property'} component={BCM} />
                  <Route exact path={appRouteDefault} component={Apply} />
                  <Route exact path={appRouteIndexed} component={Apply} />
                  <Route exact path={'/continue/direct-upload/:dealId/:accountId/:mainContactId?'} component={Apply} />
                  <Route exact path={'/continue/direct-upload/:dealId/:accountId/:mainContactId?'} component={Apply} />
                  <Route exact path={'/continue/direct-upload'} component={Apply} />
                  <Route exact path={'/continue/plaid-connect'} component={Apply} />
                  <Route exact path={'/continue/plaid'} component={Apply} />
                  <Route exact path={'/continue/upload'} component={Apply} />
                  <Route exact path={'/continue/plaid-and-upload'} component={Apply} />
                  <BrandHomeComponent route={props.route_home} />
                </ParamsFromUrlWrapper>
              </Suspense>
            </Switch>
          </div>
        </LaunchDarklyWrapper>
      </motion.div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    route_home: state.brand?.data?.route_home,
    brand_params: state.brand?.data?.brand,
  };
};

export const App = connect(mapStateToProps)(AppView);
