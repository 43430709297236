import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { setTemp } from '@store/actions';
import Agreement from './Agreement';
import devicer from '../../../../../external-libs/devicer.min.js';
import { backendUrl, isSyntheticTest } from '../../../utils/platformBasedInfo';
import { platformName } from '../../../utils/platformBasedInfo';
import { currencyToDecimal } from '../../../utils/currencyToDecimal';

const AgreementContainer = ({
  showProcessingModal,
  loan_id,
  loan_amount,
  loan_terms,
  loan_payment,
  loan_num_payments,
  loan_factor,
  callApi,
  vendor_name,
  dataLayerPush,
  nextRoute,
  first,
  history,
  ...other
}) => {
  useEffect(() => {
    if (process.env.STORYBOOK_TEST) return;

    if (platformName === 'tento') {
      var deviceFPOptions = {
        publicKey: process.env.SOCURE_PUBLIC_KEY,
        endpoint: process.env.SOCURE_URL,
        userConsent: true,
        context: 'transaction',
      };
      devicer.run(deviceFPOptions, function (response) {});
    }
  }, []);

  const confirmTerms = async () => {
    showProcessingModal('Sending you to DocuSign...');

    const post = {
      loan_id: loan_id,
      loan_amount: currencyToDecimal(loan_amount),
      loan_terms: loan_terms,
      loan_payment: loan_payment,
      loan_num_payments: loan_num_payments,
      loan_factor: loan_factor,
      base_url: backendUrl,
    };

    const response = await callApi('agreement/confirm', post, vendor_name);

    if (!isSyntheticTest) {
      dataLayerPush('sending-to-docusign', post);
    }

    if (!response.error) {
      window.location.href = response.signingUrl;
    }
  };

  const saveManualSign = async () => {
    const post = {
      loan_id: loan_id,
    };

    const response = await callApi('agreement/save-manual', post, vendor_name);

    if (!response.error) {
      nextRoute();
    }
  };

  const saveAgreementUpload = async () => {
    const post = {
      loan_id: loan_id,
      first: first,
    };

    const response = await callApi('agreement/save-upload', post, vendor_name);

    if (!isSyntheticTest) {
      dataLayerPush('final-docs-upload', post);
    }

    if (!response.error) {
      history.push('/app/done');
    }
  };

  return (
    <Agreement
      confirmTerms={confirmTerms}
      saveManualSign={saveManualSign}
      onSubmit={saveAgreementUpload}
      {...{
        showProcessingModal,
        loan_id,
        loan_amount,
        loan_terms,
        loan_payment,
        loan_num_payments,
        loan_factor,
        callApi,
        vendor_name,
        dataLayerPush,
        nextRoute,
        first,
        history,
        ...other,
      }}
    />
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    first: selector(state, 'owner_1_first'),
    loan_id: selector(state, 'loan_id'),
    loan_type: selector(state, 'loan_type'),
    loan_terms: selector(state, 'loan_terms'),
    loan_amount: selector(state, 'loan_amount'),
    loan_payment: selector(state, 'loan_payment'),
    loan_num_payments: selector(state, 'loan_num_payments'),
    loan_factor: selector(state, 'loan_factor'),
    theme: state.theme,
    vendor_name: state.brand?.data?.vendor_name,
  };
};

export default connect(mapStateToProps, { setTemp })(withRouter(AgreementContainer));
