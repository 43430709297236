import React from 'react';
import { Button, StepCard } from 'shared-components';

export const SkipConnectCardBCM = ({ asText = false, onSkipClick }: { asText?: boolean; onSkipClick: () => void }) => {
  if (asText) {
    return (
      <>
        <Button variant="text" onClick={onSkipClick}>
          Skip For Now
        </Button>
      </>
    );
  }

  return (
    <StepCard
      type="current"
      headline="Skip This Step for Now"
      textBody={<>Not ready yet? No problem. Skip this step for now and we’ll get you connected later.</>}
      cardProps={{ className: 'w-full justify-between' }}
      buttonProps={{
        onClick: onSkipClick,
        children: 'Skip for Now',
        className: 'w-full max-w-[240px] font-condensed',
      }}
      button="Skip for Now"
    />
  );
};
