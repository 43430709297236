import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { setTemp } from '@store/actions';
import { Button, Stack, StepCard, Text, capitalizeFirstLetter } from 'shared-components';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { useResponsiveBreakpoints } from 'shared-components';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';
import { StatementsUpload } from '@components/Apply/FundingIsAlmostHere/StatementsUpload';
import { PlaidConnectCardContainer } from '@components/Apply/BankConnect/PlaidConnectCard';
import { change } from 'redux-form';
import { LoanType } from '../../../types';
import { ENDPOINT_SAVE_FILE } from '../../../api/fileSave';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { isSyntheticTest } from '@utils/platformBasedInfo';

const UploadComponent: any = StatementsUpload;

export type BankConnectOrUploadProps = {
  loan_id: string;
  loan_amount: string;
  first: string;
  vendor_name: string;
  setTemp: any;
  history: any;
  match: any;
  user: any;
  haveStatements: boolean;
  typeOfStatements: string;
  owner1First: string;
  business_id: string;
  ownerOneId?: string;
  loanType: LoanType;
  isUploadOnly?: boolean;
  isPlaidOnly?: boolean;
  customFieldName?: string;
  source?: string;
};

const BankConnectOrUpload = ({
  loan_id,
  loan_amount,
  first,
  vendor_name,
  setTemp,
  history,
  match,
  user,
  haveStatements,
  typeOfStatements,
  owner1First,
  business_id,
  loanType,
  ownerOneId,
  isUploadOnly = false,
  isPlaidOnly = false,
  customFieldName,
  source = 'apply-wizard-standard-flow',
}: BankConnectOrUploadProps) => {
  const { nextRoute, callApi, dataLayerPush, showProcessingModal } = useApplyWizardContext();
  const responsiveBreakpoints = useResponsiveBreakpoints();
  const [uploadSelected, setUploadSelected] = useState(isPlaidOnly ? false : isUploadOnly ? true : false);
  const [statementsUploaded, setStatementsUploaded] = useState(false); // only used in the case with "customFieldName"
  const dispatch = useDispatch();

  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';

  const isPlaidConnected = typeOfStatements === 'plaid';
  const areStatementsUploaded = typeOfStatements === 'upload';
  const willEmailStatements = typeOfStatements === 'email';

  useEffect(() => {
    if (!loan_id) {
      history.push('/app/contact-soon');
    }
  }, [loan_id]);

  useEffect(() => {
    if (customFieldName) {
      if (statementsUploaded) {
        dispatch(reset('application'));
        history.push('/app/contact-soon');
      }
    } else {
      if (areStatementsUploaded) {
        dispatch(reset('application'));
        history.push('/app/contact-soon');
      }
    }
  }, [isPlaidConnected, areStatementsUploaded, statementsUploaded]);
  return (
    <>
      <Helmet>
        <title>Connect Bank Account - Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart
          headingText={isUploadOnly ? 'Missing Documents' : 'Connect Your Bank Account'}
          subHeadingText={
            isUploadOnly
              ? `Hello${
                  owner1First ? ` ${capitalizeFirstLetter(owner1First)}` : ''
                }, in order to complete your financing request we need the last 4 months of your business bank statements. Please upload them below.`
              : isPlaidOnly
              ? `Hello${
                  owner1First ? ` ${capitalizeFirstLetter(owner1First)}` : ''
                }, in order to complete your financing request we need you to connect your bank account via Plaid.  Please use the link below.`
              : `Hello${
                  owner1First ? ` ${capitalizeFirstLetter(owner1First)}` : ''
                }, in order to complete your financing request we need the last 4 months of your business bank statements. Please connect your bank account utilizing our partner Plaid or upload them below.`
          }
        />
        {(customFieldName ? !statementsUploaded : !areStatementsUploaded) && (
          <>
            <Stack flow="row">
              {!uploadSelected && !isPlaidConnected ? (
                <Stack gapPx="35px">
                  <Stack
                    flow={responsiveBreakpoints.isSmallDesktop || responsiveBreakpoints.isDesktop ? 'column' : 'row'}
                    gapPx="10px"
                  >
                    <Stack
                      alignItems="start"
                      justifyContent={
                        isPlaidOnly
                          ? 'center'
                          : responsiveBreakpoints.isSmallDesktop || responsiveBreakpoints.isDesktop
                          ? 'end'
                          : 'center'
                      }
                    >
                      <PlaidConnectCardContainer
                        {...{
                          user,
                          callApi,
                          history,
                          vendor_name,
                          loan_id,
                          loan_amount,
                          owner1First,
                          dataLayerPush,
                          business_id,
                          first: owner1First,
                          showProcessingModal,
                          setTemp,
                          match,
                          nextRoute,
                          customConnectEndpoint: undefined,
                          customLinkTokenEndpoint: undefined,
                          customOnConnect: () => {},
                        }}
                      />
                    </Stack>

                    {!isPlaidOnly && (
                      <Stack alignItems="start" justifyItems="start" justifyContent="start" className="relative">
                        <div>
                          {(responsiveBreakpoints.isSmallDesktop || responsiveBreakpoints.isDesktop) && (
                            <Button
                              variant="cta-small-primary"
                              className="absolute top-[140px] left-[-30px] p-0 w-[50px] h-[50px] z-10 font-condensed hover:bg-spark pointer-events-none"
                            >
                              OR
                            </Button>
                          )}
                        </div>
                        <StepCard
                          type="current"
                          headline="Upload Documents"
                          textBody="Upload the last 4 months of business bank statements for review."
                          cardProps={{ className: 'w-full justify-between' }}
                          buttonProps={{
                            onClick: () => {
                              setUploadSelected(true);
                            },
                            children: 'Upload',
                            className: 'font-condensed',
                          }}
                          button="Upload"
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Stack flow="row" gapPx="35px">
                  {isPlaidConnected && !isUploadOnly && (
                    <Alert severity="error">
                      <AlertTitle>
                        Thank you for attempting to connect your bank account, unfortunately the request was not
                        successful. Can you please upload the last 4 months of your business bank statements for further
                        review? Thank you!
                      </AlertTitle>
                    </Alert>
                  )}
                  <UploadComponent
                    {...{ loan_type: loanTypeSafe, first: owner1First, loan_id, loan_amount }}
                    dataLayerPush={dataLayerPush}
                    setTemp={setTemp}
                    withOtherOptions={false}
                    openAlert={() => {}}
                    closeAlert={() => {}}
                    withDealUpdate={false}
                    withHeading={false}
                    withSubHeading={false}
                    withSecurityText={false}
                    isUploadOnly={isUploadOnly}
                    allowSkip={false}
                    allowPlaid={!isUploadOnly && !isPlaidConnected}
                    dataMapper={(files: any) => {
                      return {
                        files,
                        dealId: loan_id,
                        accountId: business_id,
                        contactId: ownerOneId,
                      };
                    }}
                    customFieldName={customFieldName}
                    customEndpoint={`${ENDPOINT_SAVE_FILE}-continue`}
                    customOnSaved={() => {
                      if (customFieldName) {
                        setStatementsUploaded(true);
                      }
                      dispatch(change('application', 'have_statements', true));
                      dispatch(change('application', 'type_of_statements', 'upload'));
                      if (!isSyntheticTest) {
                        analytics.track('User Upload Docs', {
                          source: source,
                        });
                      }
                    }}
                    onPlaid={() => {
                      setUploadSelected(false);
                    }}
                    onSkip={() => {
                      dispatch(change('application', 'have_statements', false));
                      dispatch(change('application', 'type_of_statements', 'email'));
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Text variant="body-general" className="text-center">
              {isUploadOnly
                ? `**If your business utilizes multiple bank accounts please attach all accounts.**`
                : isPlaidOnly
                ? '**At no point will your username or password be shared with Tento.**'
                : '**At no point will your username or password be shared with Tento.  If your business utilizes multiple bank accounts please attach all accounts.**'}
            </Text>
          </>
        )}
      </Stack>
    </>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    first: selector(state, 'owner_1_first'),
    loan_id: selector(state, 'loan_id'),
    loan_amount: selector(state, 'loan_amount'),
    theme: state.theme,
    user: state.user,
    vendor_name: state.brand?.data?.vendor_name,
    business_id: selector(state, 'business_id'),
    contact_id: selector(state, 'owner_1_id'),
    mobile: selector(state, 'owner_1_mobile'),
    email: selector(state, 'owner_1_email'),
    haveStatements: selector(state, 'have_statements'),
    typeOfStatements: selector(state, 'type_of_statements'),
    owner1First: selector(state, 'owner_1_first'),
    ownerOneId: selector(state, 'owner_1_id'),
  };
};
export const BankConnectOrUploadContainer = connect(mapStateToProps, { setTemp })(withRouter(BankConnectOrUpload));
