import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';

export const ENDPOINT_POST_BCM = `${backendBaseUrl}/deals/createInTransactionBcm`;

export const postBcmStep1 = () =>
  useAxios(
    {
      url: ENDPOINT_POST_BCM,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );
